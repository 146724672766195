import React from "react";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { BlockWithVideo } from "../components/Block";
import { CommonTranslation, useCommonTranslations } from "../components/CommonTranslations";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useFilteredByLocale } from "../hooks";
import { Carousel } from "react-responsive-carousel";

export default function BehindTheScenes({ location }: { location: Location }) {
  const commonTranslations = useCommonTranslations();

  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.behindTheScenes} />
      <Body />
    </Layout>
  );
}

const Body = () => {
  const { blocks, content, contentTitle, title, btsImages } = useBehindTheScenes();

  return (
    <div className="relative w-full min-h-screen text-white">
      <StaticImage
        className="object-cover w-screen h-screen"
        alt="Behind the scenes"
        src="../images/behind-the-scenes-banner.png"
        style={{ position: "absolute", top: 0 }}
      />
      <div className="relative grid grid-cols-12 overflow-hidden mb-14 md:gap-2">
        <div className="col-span-10 col-start-2 mt-[70vh] mb-4 pl-4">
          <h1 className="text-3xl md:text-5xl">{title}</h1>
        </div>
        <div className="flex flex-col col-span-10 col-start-2 p-4 space-y-8 bg-black rounded-lg md:col-span-7 md:col-start-2 lg:col-span-6 lg:col-start-2 md:p-8">
          {blocks.map((props) => (
            <BlockWithVideo key={props.title} {...props} />
          ))}
        </div>
        <div className="flex flex-col col-span-10 col-start-2 p-4 space-y-8 bg-black rounded-b-lg md:col-span-5 md:col-start-2 lg:col-span-5 lg:col-start-2 md:pt-10 md:pl-10">
          <h1 className="text-3xl font-bold">
            <CommonTranslation value="watchMore" />
          </h1>
        </div>
        <div className="flex flex-col col-span-10 col-start-2 p-4 space-y-8 bg-black rounded-b-lg md:col-span-5 md:col-start-2 lg:col-span-5 lg:col-start-2 md:pb-10 md:px-10">
          <div>
            {content.map(({ text, url }) => (
              <a
                className={cx("flex items-center px-4 py-8 space-x-2 font-bold border-t border-white last:border-b", {
                  "hover:bg-white hover:text-black": !!url,
                })}
                href={url}
                target="_blank"
              >
                <span>{text}</span>
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center col-span-10 col-start-2 pt-4 pb-10 space-y-8 md:items-start md:col-span-5 md:flex lg:col-start-7">
          <div>
            <Carousel swipeable infiniteLoop interval={5000} showIndicators={false} showThumbs={false}>
              {btsImages.map((image) => (
                <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} alt="behind the scenes" />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

const useBehindTheScenes = (): BehindTheScenes => {
  const {
    allStrapiBehindTheScenes: { edges },
  } = useStaticQuery<{ allStrapiBehindTheScenes: EdgesQuery<{ node: BehindTheScenes }> }>(query);

  return useFilteredByLocale(edges.map(({ node }) => node))[0];
};

const query = graphql`
  query BehindTheScenes {
    allStrapiBehindTheScenes {
      edges {
        node {
          title
          blocks {
            body
          }
          contentTitle
          content {
            text
            url
          }
          locale
          btsImages {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
